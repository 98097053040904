<template>
  <div
    class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
    data-sx-menu="true"
    id="sx-notification-menu"
  >
    <div
      class="d-flex flex-column bgi-no-repeat bg-primary"
      :class="{
        rounded: notifications.length == 0,
        'rounded-top': notifications.length > 0,
      }"
    >
      <h3 class="text-white fw-bold px-9 mt-10 mb-6">
        {{
          $t(
            "Components.Notifications.Title",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
        <span class="fs-8 opacity-75 ps-3"
          >0
          {{
            $t(
              "Components.Notifications.Notification",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </h3>
    </div>
    <div class="notication-content" v-if="notifications.length > 0">
      <div class="scroll-y mh-325px my-2 px-2">
        <template v-for="(item, index) in notifications" :key="index">
          <div class="d-flex flex-stack py-4">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-4">
                <span :class="`bg-light-${item.state}`" class="symbol-label">
                  <span
                    :class="`svg-icon-${item.state}`"
                    class="svg-icon svg-icon-2"
                  >
                  </span>
                </span>
              </div>
              <div class="mb-0 me-2">
                <a
                  href="#"
                  class="fs-6 text-gray-800 text-hover-primary fw-bolder"
                  >{{ item.title }}</a
                >
                <div class="text-gray-400 fs-7">
                  {{ item.description }}
                </div>
              </div>
            </div>
            <span class="badge badge-light fs-8">{{ item.time }}</span>
          </div>
        </template>
      </div>
      <div class="py-3 text-center border-top" v-if="notifications.length > 4">
        <a href="#" class="btn btn-color-gray-600 btn-active-color-primary">
          {{
            $t(
              "BaseModelFields.ViewAll",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  data() {
    return {
      notifications: [],
    };
  },
  setup() {
    const data1 = [
      {
        title: "Project Alice",
        description: "Phase 1 development",
        time: "1 hr",
        state: "primary",
      },
      {
        title: "HR Confidential",
        description: "Confidential staff documents",
        time: "2 hrs",
        state: "danger",
      },
      {
        title: "Company HR",
        description: "Corporeate staff profiles",
        time: "5 hrs",
        state: "warning",
      },
      {
        title: "Project Redux",
        description: "New frontend admin theme",
        time: "2 days",
        state: "success",
      },
      {
        title: "Project Breafing",
        description: "Product launch status update",
        time: "21 Jan",
        state: "primary",
      },
      {
        title: "Banner Assets",
        description: "Collection of banner images",
        time: "21 Jan",
        state: "info",
      },
      {
        title: "Icon Assets",
        description: "Collection of SVG icons",
        time: "20 March",
        state: "warning",
      },
    ];

    const data2 = [
      {
        code: "200 OK",
        state: "success",
        message: "New order",
        time: "Just now",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "New customer",
        time: "2 hrs",
      },
      {
        code: "200 OK",
        state: "success",
        message: "Payment process",
        time: "5 hrs",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Search query",
        time: "2 days",
      },
      {
        code: "200 OK",
        state: "success",
        message: "API connection",
        time: "1 week",
      },
      {
        code: "200 OK",
        state: "success",
        message: "Database restore",
        time: "Mar 5",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "System update",
        time: "May 15",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Server OS update",
        time: "Apr 3",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "API rollback",
        time: "Jun 30",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Refund process",
        time: "Jul 10",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Withdrawal process",
        time: "Sep 10",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Mail tasks",
        time: "Dec 10",
      },
    ];

    return {
      data1,
      data2,
    };
  },
});
</script>
