<template>
  <div
    class="toolbar well border-bottom"
    id="sx_toolbar"
    :class="{ 'mt-0': $isMobile() }"
  >
    <div
      id="sx_toolbar_container"
      :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid,
      }"
      class="d-flex flex-stack"
    >
      <div
        data-sx-swapper="true"
        data-sx-swapper-mode="prepend"
        data-sx-swapper-parent="{default: '#sx_content_container', 'lg': '#sx_toolbar_container'}"
        class="page-title d-flex align-items-center flex-wrap mb-lg-0"
        :class="{ 'mb-1': $isMobile() }"
      >
        <template
          v-if="
            this.breadcrumbs.length > 0
              ? this.breadcrumbs[0].name === 'HELP'
                ? true
                : false
              : false
          "
        >
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </template>
        <template v-else>
          <template v-if="$isMobile()">
            <h1
              class="d-flex align-items-center justify-content-center text-dark fw-bolder my-1 fs-4 w-100"
            >
              {{ title }}
            </h1>
          </template>
          <template v-else>
            <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-4">
              {{ title }}
            </h1>
            <span
              v-if="breadcrumbs"
              class="h-20px border-gray-200 mx-4 border-left-dashed"
            ></span>
          </template>
          <ul
            class="breadcrumb breadcrumb-separatorless shadow-none fw-bold fs-7 my-1 ps-0"
            v-if="breadcrumbs"
          >
            <li class="breadcrumb-item text-muted text-capitalize">
              <router-link :to="$appHomeUrl">
                {{
                  $t("Dashboard", {}, { locale: this.$store.state.activeLang })
                }}
              </router-link>
            </li>
            <template v-for="(item, index) in breadcrumbs" :key="index">
              <li class="breadcrumb-item">
                <span class="bullet w-5px h-2px"></span>
              </li>
              <li class="breadcrumb-item text-muted" v-if="item.isRouteLink">
                <router-link :to="item.html">
                  {{ item.name }}
                </router-link>
              </li>
              <li class="breadcrumb-item text-muted" v-else-if="!item.isHtml">
                {{ item.name }}
              </li>
              <li class="breadcrumb-item text-muted" v-else>
                <span v-html="item.html"></span>
              </li>
            </template>
          </ul>
          <template v-if="$route.meta.isNewPage && !$isMobile()">
            <button
              type="button"
              class="btn btn-info ms-2"
              data-bs-target="#modalJsonViewer"
              data-bs-toggle="modal"
              @click="modelCreate()"
              v-if="
                setPageDto &&
                setPageDto.layout &&
                setPageDto.layout.isApiModelEnabled
              "
            >
              <i class="bi bi-filetype-json"></i>
              {{
                $t(
                  "BaseModelFields.ModelCreate",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </template>
        </template>
      </div>
      <div
        class="pull-right text-center"
        v-if="
          this.breadcrumbs.length > 0
            ? this.breadcrumbs[0].name === 'HELP'
              ? false
              : true
            : true
        "
      >
        <ActionButtons
          v-if="
            this.$store.getters._setPageLayoutAvailable &&
            setPageDto &&
            setPageDtoIsOk
          "
          classes=""
          :isToolbar="true"
        />
      </div>
    </div>
  </div>
  <template v-if="$route.meta.isNewPage">
    <JsonViewerModal
      :json="model"
      v-if="
        this.setPageDto &&
        this.setPageDto.layout &&
        this.setPageDto.layout.isApiModelEnabled
      "
    />
  </template>
</template>

<script>
import { toolbarWidthFluid } from "@/core/helpers/config";
import { removeTooltips } from "@/core/helpers/dom";
import $ from "jquery";

export default {
  name: "SetToolbar",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      setPageDto: this.$store.state.setPageDto,
      setPageDtoIsOk: false,
      model: {},
    };
  },
  watch: {
    "$store.state.setPageDto"() {
      this.setPageDto = this.$store.state.setPageDto;
      this.setPageDtoIsOk =
        this.setPageDto &&
        (this.setPageDto.isOk ||
          this.setPageDto.msg === this.$viewFilterFormulaErrorKey ||
          this.setPageDto.msg === this.$buttonValidationCalculateErrorKey);
    },
  },
  components: {},
  methods: {
    redirectDocumentPage() {
      removeTooltips();
      this.$router.push({ name: "documents" });
    },
    modelCreate() {
      var model = this.$root.modelCreate("#newForm"),
        modelOnlyKeyValues = model.values.map(function (m) {
          return {
            key: m.key,
            value: m.value,
          };
        });

      model.values = modelOnlyKeyValues;
      this.model = model;
    },
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
  mounted() {
    this.setPageDtoIsOk =
      this.setPageDto &&
      (this.setPageDto.isOk ||
        this.setPageDto.msg === this.$viewFilterFormulaErrorKey ||
        this.setPageDto.msg === this.$buttonValidationCalculateErrorKey);
  },
};
</script>
