<template>
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-6 w-275px py-2"
    data-sx-menu="true"
    id="sx-user-menu"
  >
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-1">
        <div class="symbol symbol-50px me-3">
          <img :src="require('@/assets/img/icons/user/user-icon-sm.png')" />
        </div>
        <div class="d-flex flex-column text-break">
          <div
            class="fw-bolder d-flex align-items-center fs-5 user-name text-dark"
          >
            {{ this.$store.getters.fullUserName }}
          </div>
          <span class="fw-bold text-hover-primary fs-7 text-dark">{{
            this.$store.getters.email
          }}</span>
        </div>
      </div>
    </div>
    <div class="separator my-2"></div>
    <div class="menu-item px-3">
      <router-link class="menu-link" to="/Account/Settings">
        {{
          $t(
            "BaseModelFields.AccountSettings",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
    </div>
    <div class="menu-item px-3">
      <a
        class="menu-link"
        :href="$config.webSites.settings"
        target="_blank"
        v-if="this.$store.getters._isSuperAdmin"
      >
        {{
          $t(
            "BaseModelFields.SettingsPanel",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </a>
    </div>
    <div class="menu-item px-3">
      <template v-if="!refreshCacheDataButtonClicked">
        <a @click="refreshCacheData" class="menu-link">
          {{
            $t(
              "BaseModelFields.CacheReload",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </a>
      </template>
      <div class="menu-link cursor-default" v-else>
        <s class="me-2">
          {{
            $t(
              "BaseModelFields.CacheReload",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</s
        >
        (<span id="cache-reload-timer" class="text-success"></span>)
      </div>
    </div>
    <div class="separator my-2"></div>
    <div
      class="menu-item px-3"
      :data-sx-menu-trigger="$isMobile() ? 'click' : 'hover'"
      :data-sx-menu-placement="$isMobile() ? 'bottom-start' : 'left-start'"
      data-sx-menu-flip="center, top"
    >
      <a class="menu-link">
        <span class="menu-title position-relative">
          {{
            $t(
              "BaseModelFields.Language",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          <span
            class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
          >
            {{ currentLangugeLocale.name }}
            <img
              class="w-15px h-15px rounded-1 ms-2"
              :src="`${require('@/assets/img/flags/' +
                currentLangugeLocale.flag)}`"
              :alt="currentLangugeLocale.name"
            />
          </span>
        </span>
      </a>
      <div class="menu-sub menu-sub-dropdown w-175px">
        <div
          class="menu-item p-2"
          v-for="item in locales.filter((item) => {
            return item.code !== this.$store.state.activeLang;
          })"
          :key="item.code"
        >
          <a
            @click="setLang(item.code)"
            class="menu-link d-flex"
            :class="{ active: this.$store.state.activeLang == item.code }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="`${require('@/assets/img/flags/' + item.code + '.svg')}`"
              />
            </span>
            {{
              $t(
                "Language." + item.code,
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </a>
        </div>
      </div>
    </div>
    <div class="menu-item header-sign-out px-3">
      <a @click="signOut()" class="menu-link">
        {{
          $t(
            "BaseModelFields.SignOut",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </a>
    </div>
  </div>
</template>
<script>
import { getSupportedLocales } from "@/core/i18n/supported-locales";
import { computed } from "vue";
import { useI18n } from "vue-i18n/index";
export default {
  name: "UserMenu",
  data() {
    return {
      locales: getSupportedLocales(),
      countries: [
        {
          flag: "uk.svg",
          name: "English",
          code: "en",
        },
        {
          flag: "tr.svg",
          name: "Türkçe",
          code: "tr",
        },
      ],
      currentLangugeLocale: {},
      refreshCacheDataButtonClicked: false,
    };
  },
  methods: {
    signOut() {
      this.$root.signOut();
    },
    setLang(code) {
      this.$root.setCurrentUserLanguage(code);
    },
    refreshCacheData() {
      this.refreshCacheDataButtonClicked = true;
      this.$root.getCurrentUser(false, true);

      var oneMinuteWithSecond = 60,
        oneMinuteWithMilliSecond = 60000;
      setTimeout(() => {
        Number.startTimer("cache-reload-timer", oneMinuteWithSecond);
      }, 10);
      setTimeout(() => {
        this.refreshCacheDataButtonClicked = false;
      }, oneMinuteWithMilliSecond + 1010);
    },
  },
  created() {
    this.currentLangugeLocale = this.countries.find(
      (f) => f.code == this.$store.state.activeLang
    );
  },
};
</script>
