<template>
  <div class="toolbar top-0 mb-0">
    <div class="well w-100">
      <div class="d-flex align-items-center justify-content-center">
        <ul class="list-group list-group-horizontal">
          <li
            v-if="this.$store.getters._isDocumentActive"
            class="list-group-item"
          >
            <div
              @click="redirectDocumentPage"
              class="d-flex align-items-stretch h-100"
            >
              <div class="d-flex align-items-center">
                <i class="fa fa-folder-open text-black fa-2x"></i>
              </div>
            </div>
          </li>
          <li
            class="list-group-item align-items-center d-flex"
            v-if="this.$store.getters._isDeletegationActive"
          >
            <Delegations :isDrawerSidePanel="true"></Delegations>
          </li>
          <li class="list-group-item">
            <Search></Search>
          </li>
          <li
            class="list-group-item"
            v-if="this.$store.getters._isSystemNotificationActive"
          >
            <div
              class="d-flex align-items-stretch h-100"
              data-sx-menu-trigger="click"
              data-sx-menu-attach="parent"
              data-sx-menu-placement="bottom-end"
              data-sx-menu-flip="bottom"
            >
              <div class="d-flex align-items-center">
                <i class="fa fa-bell text-black fa-2x"></i>
              </div>
            </div>
            <NotificationsMenu></NotificationsMenu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Search from "@/layout/header/partials/Search.vue";
import NotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import Delegations from "@/layout/header/partials/Delegations.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { removeTooltips } from "@/core/helpers/dom";
export default {
  name: "MobileTopbar",
  components: {
    Search,
    NotificationsMenu,
    Delegations,
  },
  data() {
    return {};
  },
  methods: {
    redirectDocumentPage() {
      removeTooltips();
      this.$router.push({ name: "documents" });
    },
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
};
</script>
